<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :module_name="page.name"/>
    <v-row>
      <v-col>
        <base-table ref="base_table" :page="page" @edit="edit"/>
      </v-col>
    </v-row>
    <edit-certificate ref="edit_certificate" modal_name="edit_certificate" @refresh="reload"/>
  </v-container>
</template>

<script>
import BaseTable from "../../components/commonComponents/BaseTable";
import EditCertificate from "./Edit";
import BaseBreadcrumb from "../../components/commonComponents/BaseBreadcrumb";
export default {
  components: {
    BaseTable,
    EditCertificate,
    BaseBreadcrumb,
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate("certificates"),
        name: "certificates",
        model: "certificate",
      },
    };
  },
  methods: {
    edit(item) {
      this.$refs.edit_certificate.id = item.id;
      this.$refs.edit_certificate.openModal('edit_certificate');
      this.$refs.edit_certificate.load();
    },
    reload() {
      this.$refs.base_table.run();
    },
  },
};
</script>
